import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Container, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSnackbar } from 'notistack';

import { useGetUserById } from '../../../../shared/user/hooks/user.hooks';
import AddProduct from '../../../../Containers/admin/product/AddProducts';
import AddVirtualProduct from '../../../../Containers/admin/product/AddVirtualProduct';
import ProductList from '../../../../Containers/admin/product/ProductList';
import DeliveryInfoSummary from '../../../../Containers/admin/order/Summary/DeliveryInfoSummary';
import OrderStatusFormControl from '../../../../components/Order/select/OrderStatusFormControl';
import OrderAdvStatusFormControle from '../../../../components/Order/select/OrderAdvStatusFormControl';
import { makeStyles } from '@material-ui/core/styles';
import { axiosPostRequest } from '../../../../utils/axiosRequests';
import Cookies from 'js-cookie';
import { ADMIN_ORDER_APP_PATHS } from '../../../../shared/order/constants/path.constants';

const useStyles = makeStyles((theme) => ({
  bottom: {
    display: 'grid',
    gridTemplateColumns: '4fr 1fr',
    gap: '1rem',
  },

  leftPart: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },

  action: {
    padding: theme.spacing(2),
    overflow: 'auto',
    maxHeight: '200px',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },

    '& > h4': {
      margin: '0 0 1rem 0',
    },
  },
}));

export default function UserCreateOrderPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();
  const { loading, user } = useGetUserById(id);

  // Order products
  const [products, setProducts] = useState([]);

  // Order delivery
  const [selectedDeliveryType, setSelectedDeliveryType] = useState('home');
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedDeliveryDay, setSelectedDeliveryDay] = useState(null);

  // Order status
  const [selectedStatus, setSelectedStatus] = useState('waiting');
  const [selectedAdvStatus, setSelectedAdvStatus] = useState('validate');

  const canCreateOrder = !!products.length && !!selectedDeliveryAddress && !!selectedDeliveryDay;
  const handleAddProduct = (product) => {
    if (product.type === 'virtual') return setProducts((prev) => [...prev, product]);

    const isProductAlreadyAdded = products.some((el) => el.id === product.id);

    if (isProductAlreadyAdded) {
      return setProducts((prev) =>
        prev.reduce(
          (acc, curr) => (curr.id === product.id ? [...acc, { ...curr, quantity: curr.quantity + 1 }] : [...acc, curr]),
          []
        )
      );
    }
    return setProducts((prev) => [...prev, { ...product, quantity: 1 }]);
  };

  const handleIncreaseProductQuantity = (productId) => {
    setProducts((prev) =>
      prev.reduce(
        (acc, curr) => (curr.id === productId ? [...acc, { ...curr, quantity: curr.quantity + 1 }] : [...acc, curr]),
        []
      )
    );
  };

  const handleChangeProductQuantity = (productId, quantity) => {
    const newQuantity = quantity > 0 ? quantity : 1;
    setProducts((prev) =>
      prev.reduce(
        (acc, curr) => (curr.id === productId ? [...acc, { ...curr, quantity: newQuantity }] : [...acc, curr]),
        []
      )
    );
  };

  const handleDescreaseProductQuantity = (productId) => {
    setProducts((prev) =>
      prev
        .reduce(
          (acc, curr) => (curr.id === productId ? [...acc, { ...curr, quantity: curr.quantity - 1 }] : [...acc, curr]),
          []
        )
        .filter((product) => product.quantity > 0)
    );
  };

  const handleCreateOrder = async () => {
    try {
      const token = Cookies.get('jwt');

      const newOrder = {
        userId: user.id,
        delivery: {
          type: selectedDeliveryType,
          addresses: {
            deliveryAddress: selectedDeliveryAddress,
            billingAddress: selectedBillingAddress,
          },
          deliveryDay: {
            date: selectedDeliveryDay.date.format('YYYY-MM-DD'),
            schedule: selectedDeliveryDay.schedule,
          },
        },
        status: selectedStatus,
        advStatus: selectedAdvStatus,
        products,
      };

      const response = await axiosPostRequest(`/admin/order/create`, token, newOrder);

      const orderId = response.data.data.order._id;

      window.location.href = ADMIN_ORDER_APP_PATHS.ORDER_SUMMARY.replace(':orderId', orderId);
    } catch (error) {
      if (error.response?.data?.message) enqueueSnackbar(error.response.data.message, { variant: 'error' });
      else enqueueSnackbar('Une erreur est survenue lors de la création', { variant: 'error' });
    }
  };

  return (
    <Container>
      {loading && <Skeleton variant="rect" width="100%" height={50} />}
      {!loading && user && (
        <>
          <h1>Création de commande pour {user.fullname}</h1>
          <AddProduct mb={1} handleAddProduct={handleAddProduct} />
          <AddVirtualProduct mb={1} handleAddProduct={handleAddProduct} />
          {products && (
            <ProductList
              mb={1}
              products={products}
              handleIncrease={handleIncreaseProductQuantity}
              handleChangeQuantity={handleChangeProductQuantity}
              handleDescrease={handleDescreaseProductQuantity}
            />
          )}
          <section className={classes.bottom}>
            <DeliveryInfoSummary
              addresses={user.address}
              selectedDeliveryType={selectedDeliveryType}
              selectedDeliveryAddress={selectedDeliveryAddress}
              selectedBillingAddress={selectedBillingAddress}
              selectedDeliveryDay={selectedDeliveryDay}
              setSelectedDeliveryType={setSelectedDeliveryType}
              setSelectedDeliveryAddress={setSelectedDeliveryAddress}
              setSelectedBillingAddress={setSelectedBillingAddress}
              setSelectedDeliveryDay={setSelectedDeliveryDay}
            />
            <section className={classes.leftPart}>
              <Paper className={classes.action}>
                <h4>Statut de la commande :</h4>
                <div>
                  <OrderStatusFormControl
                    selectedStatus={selectedStatus}
                    handleChange={(event) => setSelectedStatus(event.target.value)}
                  />
                  <OrderAdvStatusFormControle
                    selectedAdvStatus={selectedAdvStatus}
                    handleChange={(event) => setSelectedAdvStatus(event.target.value)}
                  />
                </div>
              </Paper>
              <Button
                disabled={!canCreateOrder}
                type="button"
                variant="contained"
                color="primary"
                onClick={handleCreateOrder}
              >
                Créer la commande
              </Button>
            </section>
          </section>
        </>
      )}
    </Container>
  );
}
