import { useGetAllRelays } from '../../../../../shared/delivery/hooks/delivery.hooks';
import React, { useMemo, useState } from 'react';
import { getDeliveryCitySchedule } from '../../../../../shared/delivery/utils/delivery.utils';
import moment from 'moment/moment';
import Skeleton from '@material-ui/lab/Skeleton';
import DeliveryRelaysFormControl from '../../../../../components/Admin/Delivery/DeliveryRelaysFormControl';
import DeliveryDateFormControl from '../../../../../components/Admin/Delivery/DeliveryDateFormControl';
import { relayToDeliveryAddress } from '../../../../../shared/delivery/utils/relay/relayDelivery.utils';

export default function RelayDeliveryController({
  selectedDeliveryAddress,
  selectedDeliveryDay,
  setSelectedDeliveryAddress,
  setSelectedBillingAddress,
  setSelectedDeliveryDay,
}) {
  const { loading, relays } = useGetAllRelays();

  const [deliveryDays, setDeliveryDays] = useState(null);

  const formattedRelays = useMemo(
    () =>
      relays
        ? relays.map((relay) => {
            const firstLetter = relay.name[0].toUpperCase();
            return {
              firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
              ...relay,
            };
          })
        : [],
    [relays]
  );

  const handleChangeDeliveryRelay = (relay) => {
    if (!relay) {
      setSelectedDeliveryAddress(null);
      setSelectedBillingAddress(null);
      return setSelectedDeliveryDay(null);
    }

    const availableDeliveryDays = getDeliveryCitySchedule({
      schedules: relay.schedules,
      interval: { from: moment(), to: moment().add(14, 'days') },
    });

    const deliveryAddress = relayToDeliveryAddress(relay, relay.schedule);
    setSelectedDeliveryAddress(deliveryAddress);

    if (availableDeliveryDays.length) {
      setDeliveryDays(availableDeliveryDays);
      return setSelectedDeliveryDay(availableDeliveryDays[0]);
    }
  };

  const handleChangeDeliveryDay = (deliveryDay) => {
    setSelectedDeliveryDay(deliveryDay);
  };

  return (
    <>
      {loading && <Skeleton />}
      {!loading && (
        <>
          <DeliveryRelaysFormControl relays={formattedRelays} onChange={handleChangeDeliveryRelay} />
          {deliveryDays && selectedDeliveryAddress && (
            <DeliveryDateFormControl
              deliveryDays={deliveryDays}
              selectedDeliveryDay={selectedDeliveryDay}
              setSelectedDeliveryDay={handleChangeDeliveryDay}
            />
          )}
        </>
      )}
    </>
  );
}
