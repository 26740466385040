import React, { forwardRef, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment/moment';
import PrintLabel from './PrintLabel';

const useStyle = makeStyles(() => ({
  root: {
    overflow: 'hidden',
    height: 0,
    width: 0,

    '& > div': {
      width: '234px',
    },
  },
}));

function ProductLabelsContainer({ productLabels, showCategoryHeader }) {
  return (
    <>
      {showCategoryHeader && (
        <div
          style={{
            color: 'black',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            pageBreakAfter: 'always',
          }}
        >
          <p
            style={{
              fontSize: '40px',
              lineHeight: '40px',
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
          >
            {'Autres'}
          </p>
        </div>
      )}
      {productLabels.map((label, index) => (
        <PrintLabel key={index} label={label} />
      ))}
    </>
  );
}

function BasketLabelsContainer({ basketLabels, showCategoryHeader }) {
  return (
    <>
      {showCategoryHeader && (
        <div
          style={{
            color: 'black',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            pageBreakAfter: 'always',
          }}
        >
          <p
            style={{
              fontSize: '40px',
              lineHeight: '40px',
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
          >
            {'Les paniers bio'}
          </p>
        </div>
      )}
      {basketLabels.map((label, index) => (
        <PrintLabel key={index} label={label} />
      ))}
    </>
  );
}

const PrintLabelsDialog = forwardRef(function PrintLabelsDialog({ isOpen, labels, showCategoryHeader = true }, ref) {
  const isValid = isOpen && labels;

  return (
    <>
      {isValid && (
        <div style={{ position: 'absolute', overflow: 'hidden', height: 0, width: 0 }}>
          <div ref={ref} style={{ width: '234px' }}>
            <BasketLabelsContainer showCategoryHeader={showCategoryHeader} basketLabels={labels.baskets} />
            <ProductLabelsContainer showCategoryHeader={showCategoryHeader} productLabels={labels.products} />
          </div>
        </div>
      )}
    </>
  );
});
export default PrintLabelsDialog;
