import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EcoDeliverySectionHeader from './header/EcoDeliverySectionHeader';
import DeliveryNoteList from '../DeliveryNoteList';

const useDeliverySectionStyle = makeStyles({
  root: {
    pageBreakInside: 'avoid',
    marginBottom: '1.5rem',
  },
});
export default function EcoDeliveryNoteSection({ delivery }) {
  const classes = useDeliverySectionStyle();
  const { invitation, orders } = delivery;

  return (
    <section className={classes.root}>
      <EcoDeliverySectionHeader invitation={invitation} ordersLen={orders.length} />
      <DeliveryNoteList orders={orders} />
    </section>
  );
}
