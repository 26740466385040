import { BASE_ADMIN_PATH } from '../../admin/constants/path.constants';

export const ADMIN_ORDER_APP_ROOT = `${BASE_ADMIN_PATH}/orders`;
export const ADMIN_MANY_ORDERS_API_ROOT = '/admin/orders';
export const ADMIN_ORDER_API_ROOT = '/admin/order';

export const ADMIN_ORDER_APP_PATHS = {
  ORDER_SUMMARY: `${ADMIN_ORDER_APP_ROOT}/:orderId/summary`,
  ARCHIVE_ORDER: `${ADMIN_ORDER_APP_ROOT}/to-archive`,
  UPDATE_ORDER: `${ADMIN_ORDER_APP_ROOT}/update-order/:orderId`,

  DELIVERY_NOTES: `${ADMIN_ORDER_APP_ROOT}/delivery-notes`,
};

export const ADMIN_MANY_ORDERS_API_PATHS = {
  GET_LABELS_BY_DATE: `${ADMIN_MANY_ORDERS_API_ROOT}/labels/:deliveryDate`,
  TO_ARCHIVE: `${ADMIN_MANY_ORDERS_API_ROOT}/to-archive`,
  ARCHIVE: `${ADMIN_MANY_ORDERS_API_ROOT}/archive`,
  UNARCHIVE: `${ADMIN_MANY_ORDERS_API_ROOT}/unarchive`,
  GET_DELIVERY_NOTES: `${ADMIN_MANY_ORDERS_API_ROOT}/delivery/notes/:deliveryDate`,
  DOWNLOAD_ORDERS_LIST_XLSX: `${ADMIN_MANY_ORDERS_API_ROOT}/delivery/notes/download/:deliveryDate`,
};

export const ADMIN_ORDER_API_PATHS = {
  ARCHIVE_BY_ID: `${ADMIN_ORDER_API_ROOT}/archive`,
  GET_LABELS_BY_ID: `${ADMIN_ORDER_API_ROOT}/labels/:orderId`,
};
