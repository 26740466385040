import React, { useMemo, useRef, useState } from 'react';

import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Loop,
  ShoppingCart as ShoppingCartIcon,
  ShoppingBasket as ShoppingBaskerIcon,
  Payment as PaymentIcon,
  Inbox as InboxIcon,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import OrderStatCardsContainer from '../../../../Containers/admin/order/Stats/OrderStatCardsContainer';
import OrderTableWithDetails from '../../../../Containers/admin/order/Table/Table/OrderTableWithDetails';
import {
  useArchiveOrdersByDate,
  useGetOrdersToArchive,
  useUnarchiveOrdersByDate,
} from '../../../../shared/order/hooks/order.hooks';
import { formatPrice } from '../../../../shared/utils/format-price';
import AdvStatusChip from '../../../../components/Admin/Order/Chip/AdvStatusChip';
import PaymentStatusChip from '../../../../components/Admin/Order/Chip/PaymentStatusChip';
import PrintLabelsDialog from '../../../../components/Order/Dialog/PrintLabelsDialog';

const ORDER_TABLE_HEADERS = [
  { key: 'owner', label: 'Client', minWidth: 250, align: 'left' },
  {
    key: 'status',
    label: 'Statut',
    minWidth: 150,
    align: 'left',
  },
  {
    key: 'advSatus',
    label: 'ADV',
    minWidth: 150,
    align: 'left',
  },
  {
    key: 'total',
    label: 'Montant',
    minWidth: 100,
    align: 'left',
  },
  {
    key: 'deliveryDay',
    label: 'Livraison le',
    minWidth: 100,
    align: 'left',
  },
  {
    key: 'created',
    label: 'Création le',
    minWidth: 150,
    align: 'right',
  },
];

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    height: '86vh',

    '& > *': {
      flex: '1 1 1 auto',
    },

    '& > h1': {
      margin: '0 0 0.5rem 0',
    },
  },

  actions: {
    display: 'grid',
    gridTemplateColumns: '2fr 2fr',
    alignItems: 'end',
    gap: '0.5rem',
    padding: '1rem',
  },

  search: {
    '& > h4': {
      margin: '0 0 1rem 0',
    },

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
  },

  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0.5rem',
  },
});

function ActionContainer({
  deliveryDate,
  setDeliveryDate,
  setSegment,
  hasOrders,
  archiveOrdersByDate,
  unarchiveOrdersByDate,
}) {
  const classes = useStyles();

  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
  const [isDateIgnored, setIsDateIgnored] = useState(false);

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    setDeliveryDate(checked ? undefined : tomorrow);
    setIsDateIgnored(checked);
  };

  const handleDeliveryDateChange = (event) => {
    const { value } = event.target;
    setIsDateIgnored(false);
    setDeliveryDate(value);
  };

  const handleSegmentChange = (event) => {
    const { value } = event.target;
    if (value === 'all') return setSegment(undefined);
    setSegment(value);
  };

  return (
    <>
      <Paper className={classes.actions}>
        <section className={classes.search}>
          <h4>Filtrer les commandes :</h4>
          <div>
            <FormControl variant="outlined">
              <InputLabel>Segment</InputLabel>
              <Select native defaultValue="all" label="Segment" onChange={handleSegmentChange}>
                <option value="all">Tous</option>
                <option value="user">Utilisateur simple</option>
                <option value="subscriberBtoC">Abonnée B2C</option>
                <option value="subscriberBtoB">Abonnée B2B</option>
                <option value="eco">Eco-acteur</option>
                <option value="conciergerie">Conciergerie</option>
                <option value="admin">Admin</option>
                <option value="superAdmin">Super admin</option>
              </Select>
            </FormControl>
            <TextField
              label="Date de livraison"
              type="date"
              variant="outlined"
              value={deliveryDate ?? ''}
              InputLabelProps={{ shrink: true }}
              onChange={handleDeliveryDateChange}
            />
            <FormControlLabel
              control={<Checkbox checked={isDateIgnored} color="secondary" onClick={handleCheckboxChange} />}
              label="Ignorer la date"
            />
          </div>
        </section>
        <section className={classes.btnContainer}>
          <Button
            disabled={!Boolean(deliveryDate)}
            variant="contained"
            color="secondary"
            startIcon={<Loop />}
            onClick={unarchiveOrdersByDate}
          >{`Annuler`}</Button>
          <Button
            disabled={!hasOrders || !Boolean(deliveryDate)}
            variant="contained"
            color="primary"
            startIcon={<InboxIcon />}
            onClick={archiveOrdersByDate}
          >
            {'Archiver / Imprimer'}
          </Button>
        </section>
      </Paper>
    </>
  );
}

export default function AdminArchiveOrderPage() {
  const classes = useStyles();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
  const [deliveryDate, setDeliveryDate] = useState(tomorrow);
  const [segment, setSegment] = useState(undefined);
  const [trigger, setTrigger] = useState(false);

  const [labels, setLabels] = useState(undefined);
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);

  const handleDeliveryDateChange = (value) => setDeliveryDate(value);

  const { loading, orders, stats } = useGetOrdersToArchive({ deliveryDate, segment, trigger });

  const archiveOrdersByDate = useArchiveOrdersByDate({ deliveryDate, segment });
  const unarchiveOrdersByDate = useUnarchiveOrdersByDate({ deliveryDate, segment });

  const handleArchiveOrdersByDate = () => {
    setLabels(undefined);
    archiveOrdersByDate({ includeLabels: true }).then((response) => {
      const { labels } = response.data;
      if (labels.products.length || labels.baskets.length) {
        setLabels(labels);
        setIsPrintDialogOpen(true);
        handlePrint();
      }
      setTrigger((prev) => !prev);
    });
  };

  const handleUnarchiveOrdersByDate = () => {
    setLabels(undefined);

    unarchiveOrdersByDate().then(() => {
      setTrigger((prev) => !prev);
    });
  };
  const memoizedStats = useMemo(() => {
    if (!stats) return undefined;

    return [
      {
        key: 'total',
        title: 'Total potentiel',
        value: formatPrice(stats.total),
        Icon: AccountBalanceWalletIcon,
      },
      {
        key: 'totalPaid',
        title: 'Total payé',
        value: formatPrice(stats.totalPaid),
        Icon: PaymentIcon,
      },
      {
        key: 'orderCount',
        title: 'Commandes',
        value: stats.count,
        Icon: ShoppingCartIcon,
      },
      {
        key: 'productCount',
        title: 'Produits',
        value: stats.productsCount,
        Icon: ShoppingBaskerIcon,
      },
    ];
  }, [stats]);

  const formattedOrdersRows = orders.map((order) => ({
    order,
    cells: [
      {
        key: 'owner',
        content: `${order.owner.lastname} ${order.owner.firstname}`,
        minWidth: 250,
        align: 'left',
      },
      {
        key: 'status',
        content: <PaymentStatusChip status={order.status} />,
        minWidth: 150,
        align: 'left',
      },
      {
        key: 'advStatus',
        content: <AdvStatusChip advStatus={order.advStatus} />,
        minWidth: 150,
        align: 'left',
      },
      {
        key: 'totalPaid',
        content: formatPrice(order.stats.total),
        minWidth: 150,
        align: 'left',
      },
      {
        key: 'deliveryDay',
        content: moment(order.deliveryInfos.deliveryDate).format('DD/MM/YYYY'),
        minWidth: 100,
        align: 'left',
      },
      {
        key: 'created',
        content: moment(order.createdAt).format('DD MMMM YYYY à HH:mm'),
        minWidth: 150,
        align: 'right',
      },
    ],
  }));

  return (
    <section className={classes.root}>
      <h1>Archiver des commandes</h1>
      {loading && <Skeleton variant="rect" height={300} animation="wave" />}
      {!loading && (
        <>
          <PrintLabelsDialog ref={componentRef} isOpen={isPrintDialogOpen} labels={labels} />
          {memoizedStats && <OrderStatCardsContainer stats={memoizedStats} />}
          <ActionContainer
            deliveryDate={deliveryDate}
            setDeliveryDate={handleDeliveryDateChange}
            hasOrders={Boolean(orders.length)}
            setSegment={setSegment}
            archiveOrdersByDate={handleArchiveOrdersByDate}
            unarchiveOrdersByDate={handleUnarchiveOrdersByDate}
          />
          <OrderTableWithDetails headers={ORDER_TABLE_HEADERS} orders={formattedOrdersRows} />
        </>
      )}
    </section>
  );
}
